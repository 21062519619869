import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService, HTTP_SERVICE_TOKEN, IHttpServiceConfiguration, Profile, ProfileService, ProfileUrlConfiguration, ProfileWrite, PROFILE_URL_TOKEN } from '@asf/ng16-library';
import { map, Observable } from 'rxjs';


@Injectable()
export class CustomProfileService extends ProfileService<Profile, ProfileWrite> {
	constructor(router: Router, httpService: HttpService, @Inject(HTTP_SERVICE_TOKEN) httpConfiguration: IHttpServiceConfiguration, @Inject(PROFILE_URL_TOKEN) profileConfiguration: ProfileUrlConfiguration) {
		super(router, httpService, profileConfiguration, httpConfiguration)
	}

	public getAllAuthorityPermissions = (): Observable<string[]> => {
		return this.httpService.get(`${this.profileUrlConfiguration.profileUrl}/backofficeprofile/permissions/authority`).pipe(map(result => {
			return result.selectMany(sm => sm.permissions);
		}));
	}

}

