export enum WorkloadStatus {
    Assigned = "Assigned",
    Accepted = "Accepted",
    Completed = "Completed",
    Rejected = "Rejected"
}

export class WorkloadsSummary {
    public workloadsPerApplication: ApplicationWorkload[] = [];
    public workloadsPerStatus: StatusWorkload[] = [];
}

export class ApplicationWorkload {
    constructor(public applicationId: number, public value: number) {

    }
}

export class StatusWorkload {
    constructor(public status: WorkloadStatus, public value: number) {

    }
}
