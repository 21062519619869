import { Route } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { EmptyLayoutComponent } from '@asf/ng16-library';
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
    {
        path: '', component: LayoutComponent, children: [
            { path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule) },
            { path: 'authorities', loadChildren: () => import('./shared/authorities-choicer.module').then(m => m.AuthoritiesChoicerModule) },
            { path: 'profile', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule) }
        ]
    },
    {
        path: '', component: EmptyLayoutComponent, loadChildren: () => import('./pages/common/common.module').then(m => m.CommonPagesModule)
    }
];


