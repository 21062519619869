import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from '@asf/ng16-library';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard {

    constructor(protected _authService: AuthenticationService) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (!this._authService.isAuthenticated()) {
            this._authService.startLogin();
            return false;
        }
        return true;
    }
}