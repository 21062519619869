import { environment } from "environments/environment";

export class Application {
    constructor(public id: number, public name: string, public url: string, public logoUrl: string) {
        const origin = window.location.origin
        for (let i = 0; i < environment.hostsToReplace.length; i++) {
            const host = environment.hostsToReplace[i];
            this.url = `${origin}${url}`;
            this.logoUrl = `${origin}${url}${logoUrl}`;
            if (url.startsWith(host.from)) {
                this.url = url.replace(host.from, host.to);
                this.logoUrl = logoUrl.replace(host.from, host.to);
                break;
            }
        }
    }
}

// export class FullApplication extends Application {
//     constructor(id: number, name: string, url: string, logoUrl: string, public enabled: boolean) {
//         super(id, name, url, logoUrl)
//     }
// } 