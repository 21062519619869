import { Injectable } from '@angular/core';
import { HttpService } from '@asf/ng16-library';
import { ApplicationWorkload, StatusWorkload, WorkloadsSummary, WorkloadStatus } from 'app/models/workloads.model';
import { environment } from 'environments/environment';
import { map, Observable } from 'rxjs';


@Injectable()
export class WorkloadsService {
	constructor(private _http: HttpService) {
	}

	public getAll = (): Observable<WorkloadsSummary> => {
		return this._http.get(`${environment.workloadsApiUrl}/workloads/dashboard`).pipe(map(result => {
			const ret = new WorkloadsSummary();
			for (const key in result.workloadsPerApplication) {
				ret.workloadsPerApplication.push(new ApplicationWorkload(+key, result.workloadsPerApplication[key]))
			}
			for (const key in result.workloadsPerStatus) {
				ret.workloadsPerStatus.push(new StatusWorkload(<WorkloadStatus>key, result.workloadsPerStatus[key]))
			}
			return ret;
		}))
	}
}


