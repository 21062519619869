import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import localeIt from '@angular/common/locales/it';
import localeEn from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';
import { HttpService, HTTP_SERVICE_TOKEN, IHttpServiceConfiguration, ProfileService, PROFILE_URL_TOKEN, AuthenticationService, ProfileUrlConfiguration } from '@asf/ng16-library';
import { AuthGuard } from './auth.guard';
import { environment } from 'environments/environment';
import { filter, mergeMap, share } from 'rxjs/operators';
import { merge } from 'rxjs';
import { ApplicationsService } from 'app/services/applications.service';
import { WorkloadsService } from 'app/services/workloads.service';
import { CustomProfileService } from 'app/services/custom-profile.service';
registerLocaleData(localeIt);
registerLocaleData(localeEn);

export function loadAuthenticationConfiguration(authService: AuthenticationService, profileService: ProfileService) {
    const baseObs = authService.verifyLogin().pipe(share());
    const authenticatedObs = baseObs.pipe(filter(result => result === true))
        .pipe(mergeMap(() => {
            return profileService.initialize();
        }))
    const unAuthenticatedObs = baseObs.pipe(filter(result => result !== true));

    const obs = merge(authenticatedObs, unAuthenticatedObs)
    return () => obs.toPromise();
}

export function initializeHttpServiceToken(authService: AuthenticationService) {
    return <IHttpServiceConfiguration>{
        authenticationService: authService,
        errorUrl: 'errors'
    };
}


@NgModule()
export class CoreModule {
    public static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,

            providers: [
                ApplicationsService,
                WorkloadsService,
                HttpService,
                AuthenticationService,
                { provide: HTTP_SERVICE_TOKEN, useFactory: initializeHttpServiceToken, deps: [AuthenticationService] },
                AuthGuard,
                {
                    provide: APP_INITIALIZER,
                    useFactory: loadAuthenticationConfiguration,
                    deps: [AuthenticationService, ProfileService],
                    multi: true
                },
                {
                    provide: PROFILE_URL_TOKEN, useFactory: () => {
                        return <ProfileUrlConfiguration>{
                            profileUrl: environment.securityApiUrl,
                            coreUrl: environment.coreApiUrl,
                            applicationId: null
                        }
                    }
                },
                {
                    provide: ProfileService,
                    useClass: CustomProfileService
                }
            ]
        };
    }
    constructor(private _domSanitizer: DomSanitizer, private _matIconRegistry: MatIconRegistry, @Optional() @SkipSelf() parentModule?: CoreModule) {
        // Do not allow multiple injections
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
        }

        // Register icon sets
        this._matIconRegistry.registerIcons(this._domSanitizer);
    }
}

