import { NgModule } from '@angular/core';
import { EmptyLayoutModule, UserMenuModule } from '@asf/ng16-library';
import { ClassicLayoutModule } from 'app/layout/layouts/vertical/classic.module';
import { LayoutComponent } from './layout.component';

const layoutModules = [
    EmptyLayoutModule,
    ClassicLayoutModule
];

@NgModule({
    declarations: [
        LayoutComponent
    ],
    imports: [
        UserMenuModule,
        ...layoutModules
    ],
    exports: [
        LayoutComponent
    ]
})
export class LayoutModule {
}
