
import { FuseNavigationItem, IconConstants } from "@asf/ng16-library";

/* tslint:disable:max-line-length */
export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: IconConstants.menu.home,
        link: '/dashboard',
        permissions: [],
    },
    {
        id: 'applications',
        title: 'applications.applications',
        type: 'basic',
        icon: 'far:bookmark',
        link: '/applications',
        permissions: [],
    }
];

