import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpService, HTTP_SERVICE_TOKEN, Identity, IHttpServiceConfiguration, ProfileService } from '@asf/ng16-library';
import { Application } from 'app/models/applications.model';
import { environment } from 'environments/environment';
import { catchError, forkJoin, from, map, mergeMap, Observable, of } from 'rxjs';


@Injectable()
export class ApplicationsService {
	private _host: string = window.location.origin;
	constructor(private _http: HttpService, private _profileService: ProfileService, private _httpClient: HttpClient, @Inject(HTTP_SERVICE_TOKEN) private _configuration: IHttpServiceConfiguration) {
	}

	public getAvailableApplications = (): Observable<Application[]> => {
		return forkJoin({
			myApplications: this.myApplications(),
			allApplications: this.getJsonClients()
		}).pipe(map(result => {
			return result.myApplications.innerJoin(result.allApplications, i => i, p => p.applicationId, (i, p) => {
				return new Application(i, p.name, p.url, p.logoUrl);
			});
		}));
	}

	public myApplications = (): Observable<number[]> => {
		return this._http.get(`${environment.securityApiUrl}/backofficeprofile/applications`);
	}

	public getApplications = (): Observable<Application[]> => {
		return this.getJsonClients().pipe(map(result => {
			return result.map(m => {
				return new Application(m.applicationId, m.name, m.url, m.logoUrl);
			})
		}));
	}

	private getJsonClients = (): Observable<JsonClient[]> => {
		return from(fetch("https://webclients.smartpa.cloud/clients.json").then(function (response) {
			return response.json();
		})).pipe(map(m => {
			return m.filter(f => f.type != JsonClientTypes.Home)
		}))
	}

	private getHttpCalls = (url: string): Observable<any> => {
		let ret = new HttpHeaders();
		const accessToken = this._configuration.authenticationService.getAccessToken();
		if (accessToken) {
			ret = this.addHeader("Authorization", "Bearer " + accessToken, ret);
		}

		if (Identity.authorityId) {
			ret = this.addHeader("AuthorityId", Identity.authorityId.toString(), ret);
		}
		return this._httpClient.get(url, {
			headers: ret
		}).pipe(catchError(() => of([])));
	}
	private addHeader = (key: string, value: string, headers: HttpHeaders): HttpHeaders => {
		if (headers[key] == undefined) {
			headers = headers.set(key, value);
		}
		return headers;
	}
}


class JsonClient {
	public name: string;
	public url: string;
	public logoUrl: string;
	public apiUrl: string;
	public smartPARoles: string[] = [];
	public type: JsonClientTypes;
	public applicationId: number = 0;
}

enum JsonClientTypes {
	Vertical = "vertical",
	Public = "public",
	SmartPA = "smartpa",
	Home = "home"
}


