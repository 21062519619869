import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FooterModule, FuseNavigationModule, SharedModule, UserMenuModule } from '@asf/ng16-library';
import { ClassicLayoutComponent } from './classic.component';

@NgModule({
    declarations: [
        ClassicLayoutComponent
    ],
    imports: [
        HttpClientModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        FuseNavigationModule,
        UserMenuModule,
        FooterModule,
        SharedModule
    ],
    exports: [
        ClassicLayoutComponent
    ]
})
export class ClassicLayoutModule {
}
